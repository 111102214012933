@import "./Header.scss";
@import "./SignIn.scss";
@import "./Posts.scss";
@import "./Loader.scss";
@import "./Chat.scss";
@import "./SplashScreen.scss";
@import "./PostForm.scss";
@import "./Footer.scss";
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Alfa+Slab+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Playwrite+CU:wght@100..400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
$c1: #e9c46a;
$c2: #2a9d8f;
$c3: #264653;
$c4: #f4a261;
$c5: #e76f51;
$c6: #162730;
$black-selected: rgb(43, 43, 43);
$white-selected: rgb(235, 235, 235);
.colorcheck {
  color: orange;
}
.dark {
  color: $white-selected;
  background-color: $black-selected;
}

.light {
  background-color: $white-selected;
  color: $black-selected;
}

.App {
  display: grid;
  justify-items: center;
  user-select: none;
}

.header {
  grid-column: 1 / span 5;
  grid-row: 1;
}

.main-content {
  grid-column: 1 / span 5;
  grid-row: 2;
}

button {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .aside {
    display: none;
  }

  .main-content {
    padding: 0 15px 80px;
    min-width: 300px;
    min-height: 90dvh;
  }
}

@media screen and (min-width: 767px) {
  .largerscreenhamburger {
    display: none;
  }
  .main-content {
    padding: 0 1rem;
    grid-column: 2 / span 3;
    grid-row: 2;
  }

  .aside {
    font-size: 1.5rem;
    padding: 0 1.5rem;
    position: sticky;
    top: 7rem;
    min-width: 60px;
    height: 87vh;
  }

  .aside-left {
    left: 0;
    grid-column: 1 / span 1;
    grid-row: 2;
  }

  .aside-right {
    right: 0;
    grid-column: 5 / span 1;
    grid-row: 2;
  }

  .menu-item {
    cursor: pointer;
    padding: 1rem 0;
    font-weight: bold;
  }

  .menu-item:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 1024px) {
  .main-content {
    max-width: 800px;
  }
}
