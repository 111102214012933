///////////////////////////////////////////
///                   Header            ///
///////////////////////////////////////////

.header {
  display: grid;
  width: 100%;
  height: 7rem;
  background-color: #3c096c;
  color: rgb(235, 235, 235);
  position: fixed;
  top: 0;
  z-index: 1;
}

.header .header-title {
  grid-column: 1 / span 4;
  grid-row: 1;
  font-size: 2rem;
  font-family: "Playwrite CU", cursive;
  font-weight: 700;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.header .dark-button-container {
  grid-column: 5 / span 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 1em;
  cursor: pointer;
}

.dark-mode-button {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background-color: #868686;
  border-radius: 15px;
  position: relative;
}

.dark-mode-button .on,
.dark-mode-button .off {
  border-radius: 50%;
  position: absolute;
  top: 0;
  transition: 0.3s ease;
}

.dark-mode-button .on {
  left: 25px;
}

.dark-mode-button .off {
  left: 0;
}

.nav-bar {
  grid-column: 1 / span 4;
  grid-row: 2;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  font-weight: bold;
}

.nav-bar .nav-item {
  padding-left: 20px;
}
.nav-bar .nav-item:hover {
  text-decoration: underline;
}

.nav-bar-end {
  grid-column: 5 / span 1;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 1em;
}

@media screen and (max-width: 425px) {
  .header {
    height: 80px;
  }
  .header .header-title {
    font-size: 35px;
  }

  .nav-bar {
    font-size: 1rem;
  }
  .nav-bar .nav-item {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 80px;
  }
  .header .header-title {
    font-size: 35px;
  }
}

@media screen and (min-width: 767px) {
  .header {
    height: 80px;
  }
  .nav-hide {
    display: none;
  }
}
