.only-for-chat {
  padding: 0;
  max-width: 700px;
}

.chat-title {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.messages-container {
  height: 60vh;
  overflow: auto;
  padding: 10px;
}

.user-message-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.chat-left {
  align-items: flex-start;
}

.chat-right {
  align-items: flex-end;
}

.chat-user-name {
  font-size: 1rem;
  font-family: "Playwrite CU", cursive;
}
.chat-user-msg {
  font-size: 1rem;
  border-radius: 15px;
  padding: 10px 15px;
  color: white;
  width: fit-content;
  max-width: 70%;
  min-width: 60px;
  border: 1px solid black;
}

.message-send {
  display: flex;
}

.send-message-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.send-message-container input {
  height: 48px;
  border-radius: 15px 0 0 15px;
  border: 1px solid rgb(255, 255, 255);
}

.send-message-container button {
  height: 48px;
  width: 20%;
  border-radius: 0px 15px 15px 0px;
  border: 1px solid rgb(255, 255, 255);
  background-color: #003108;
  color: white;
  font-weight: bold;
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .only-for-chat {
    padding: 0;
    max-width: 700px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .messages-container {
    min-width: 500px;
  }

  .only-for-chat {
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .messages-container {
    min-width: 700px;
  }

  .only-for-chat {
    padding: 0;
  }
}
