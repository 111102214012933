.signin-box {
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

input[type="text"],
input[type="password"],
form #message {
  background-color: rgb(189, 189, 189);
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  font-size: 1em;
  box-sizing: border-box;
}

form #message {
  height: 150px;
}

input[type="text"]:focus,
input[type="password"]:focus,
form #message:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.forget {
  text-align: right;
  font-size: 0.9em;
  color: #007bff;
  margin-bottom: 20px;
  cursor: pointer;
}

.forget:hover {
  text-decoration: underline;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #e9c46a;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  filter: brightness(85%);
}

.account-create {
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}

.account-create b {
  color: #007bff;
}

.account-create:hover {
  text-decoration: underline;
}
