.splash-screen {
  height: 100vh;
  background-color: green;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.qtext {
  max-width: 600px;
  font-size: 2.5rem;
  text-align: center;
}
.text {
  max-width: 600px;
  font-size: 2rem;
  text-align: center;
}

.splash-button {
  width: 300px;
  height: 50px;
  background-color: yellow;
  border-radius: 20px;
}
