.search-sort-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.search-sort-container .search-side {
  flex: 1;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
}

.search-sort-container .search-side input {
  background-color: #f5f5f5;
  border: 1px solid;
  font-size: 25px;
  height: 48px;
  padding-left: 10px;
  border-radius: 10px 0 0 10px;
}

.search-sort-container .search-side .search-icon {
  background-color: #e9c46a;
  color: #525252;
  width: 48px;
  height: 46px;
  font-size: 2rem;
  border: 1px solid;
  border-radius: 0 10px 10px 0;
}

.search-sort-container .sort-side {
  font-size: 1.5rem;
  position: relative;
  display: inline-block;
}

.dropdown-item {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  color: #525252;
  min-width: 80px;
  z-index: 1;
}

.dropdown-item div {
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

.sort-side:hover .dropdown-item {
  display: block;
}

.sort-side:hover .dropdown-item div:hover {
  color: white;
  background-color: black;
}

.post {
  border-radius: 1.2rem;
  border: 1px solid rgb(131, 131, 131);
  padding: 15px;
  margin: 0.5rem 0;
  box-shadow: 10px 0.9rem 1.5rem -0.9rem #888888e1;
}

.post-title {
  font-family: "Alegreya", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.emoteContainer {
  font-size: 1.4em;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}

.post-date {
  opacity: 90%;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.post-message {
  font-size: 0.9em;
}

.post .reaction-signature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-size: 14px;
}

.post .reaction-signature .post-reaction {
  color: rgb(117, 117, 255);
  cursor: pointer;
}

.post-reaction .like-icon {
  font-size: 1.2rem;
}
.post-reaction .like-icon:hover {
  font-size: 1.5rem;
}

.post .post-message:last-child div {
  font-family: "Playwrite CU", serif;
}

.show-more {
  margin-bottom: 90px;
  text-align: center;
  cursor: pointer;
}

.show-more:hover {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .search-sort-container .search-side {
    width: 200px;
  }
}
