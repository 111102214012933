.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 3px 0;
  }

  50% {
    transform: rotate(180deg);
    box-shadow: 0 3px 0;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0 3px 0;
  }
}
