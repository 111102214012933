.footer {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: grid;
  align-items: center;
  text-align: center;
  font-size: 48px;
  border-radius: 0;
}

.lightthemefooter {
  background-color: rgb(245, 244, 244);
  color: rgb(27, 27, 27);
}

.darkthemefooter {
  background-color: rgb(27, 27, 27);
  color: rgb(230, 229, 229);
}

.footer .item1 {
  grid-column: 1 / span 1;
  grid-row: 1;
}
.footer .item1:hover,
.item2:hover,
.item3:hover,
.item4:hover,
.item5:hover {
  color: #7302dd;
}

.footer .item2 {
  grid-column: 2 / span 1;
  grid-row: 1;
}
.footer .item3 {
  grid-column: 3 / span 1;
  grid-row: 1;
}
.footer .item4 {
  grid-column: 4 / span 1;
  grid-row: 1;
}
.footer .item5 {
  grid-column: 5 / span 1;
  grid-row: 1;
}

.footer-menu {
  right: 0;
  position: fixed;
  bottom: 80px;
  z-index: 1;
  display: none;
  align-items: center;
  text-align: right;
  font-size: 25px;
  padding: 10px 20px;
  border-radius: 10px 0 0 0;
}

.item5:hover .footer-menu {
  display: block;
}

.lightthemefootermenu {
  background-color: rgb(138, 138, 138);
  color: rgb(0, 0, 0);
}

@media screen and (min-width: 767px) {
  .footer {
    display: none;
  }
}
